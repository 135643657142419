import React, { useContext } from 'react';
import { Tag } from 'primereact/tag';
import { Button } from 'primereact/button';
import ApplicationContext from '../../../contexts/application-context/application-context';
import { BEGATE_DECLARE } from '../../../contexts/application-context/application-keys';

const ItemTemplate = ({file, onTemplateRemove, ...props}) => {

	const {currentApplication} = useContext(ApplicationContext);

	let regex = currentApplication.ofType(BEGATE_DECLARE)
		? /^IN_[a-zA-Z0-9-]*_[0-9]{14}-1-1A.xlsx/
		:/^[a-zA-Z0-9-]*_[0-9]{8}_[0-9]{4}_(?:BRU|LGG)(?:_IOSS|).xlsx/


	let filename = file.name;
	let validFilename = regex.test(filename)

	return (
		<div className="flex align-items-center flex-wrap mb-1" style={{background: `${validFilename? 'var(--green-200)' : 'rgba(255,99,71,0.3)'}`}} >
			<div className="flex align-items-center" style={{width: '40%'}}>
					<span className="flex flex-column text-left ml-3">
                        {file.name}
                    </span>
			</div>
			<Tag value={props.formatSize} severity="warning" className="px-3 py-2" />
			<div className={'text-right ml-auto'}>
				<Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-1" onClick={() => onTemplateRemove(file, props.onRemove)} />
			</div>
		</div>
	)
}

export default ItemTemplate;
