import React from 'react';
import { Switch } from 'react-router-dom';
import ProtectedRoute from '../../auth/protected-route';
import Portal from './portal';

const XcPortalApplicationRouter = () => {
	return <Switch>
		<ProtectedRoute path={`/`} component={Portal}/>
	</Switch>
}

export default XcPortalApplicationRouter;
