import React, { useContext, useEffect, useState } from 'react';

import { FileUpload } from 'primereact/fileupload';
import { useAuth0 } from '@auth0/auth0-react';
import ItemTemplate from './item-template';
import ToastContext from '../../../contexts/toast-context';
import ApplicationContext from '../../../contexts/application-context/application-context';

const FileUploader = ({onUploadFinish, ...props}) => {
	const [token, setToken] = useState();
	const {getAccessTokenSilently} = useAuth0();

	const toast = useContext(ToastContext)
	const application = useContext(ApplicationContext)

	useEffect(() => {
		const getTheToken = async () => {
			try {
				const token = await getAccessTokenSilently();
				setToken(token);
			} catch (e) {
				toast ({severity: 'error', summary: 'Could not get token', detail: e.detail})
				console.error(e)
			}
		};

		getTheToken();
	}, [token, getAccessTokenSilently, toast, setToken] )

	const chooseOptions = {icon: 'pi pi-fw pi-file', iconOnly: true, className: 'p-button-sm p-button-rounded p-button-outlined'};
	const uploadOptions = {icon: 'pi pi-fw pi-cloud-upload', iconOnly: true, className: 'p-button-sm p-button-success p-button-rounded p-button-outlined'};
	const cancelOptions = {icon: 'pi pi-fw pi-times', iconOnly: true, className: 'p-button-sm p-button-danger p-button-rounded p-button-outlined'};

	const onTemplateRemove = (file, callback) => { callback(); }

	const itemTemplate = (file, props) => {
		return ( <ItemTemplate file={file} onTemplateRemove={onTemplateRemove} {...props}/> )
	}

	const emptyTemplate = () => {
		return (
			<div className="flex align-items-center flex-column">
				<span style={{'fontSize': '1.2em', color: 'var(--text-color-secondary)'}} className="my-2">Drag and Drop File Here</span>
				<span style={{'fontSize': '1.2em', color: 'var(--text-color-secondary)'}} className="my-2">
					Filename format: id-AWB_YYYYMMDD_HHMM_AIRPORT_IOSS.xlsx
				</span>
				<span style={{'fontSize': '0.9em', color: 'var(--text-color-secondary)'}} className={'my-1'}> AIRPORT can be BRU or LGG </span>
				<span style={{'fontSize': '0.9em', color: 'var(--text-color-secondary)'}}> e.g. id-753-14852_20210701_1542_BRU_IOSS.xlsx </span>
			</div>
		)
	}

	return (
		<React.Fragment>
			<FileUpload
				name={'image'}
				emptyTemplate={emptyTemplate}
				chooseOptions={chooseOptions}
				uploadOptions={uploadOptions}
				cancelOptions={cancelOptions}
				itemTemplate={itemTemplate}
				onError={({xhr, files}) => {
					toast ({severity: 'error', summary: 'Could not upload file!', detail: JSON.parse(xhr.responseText).message, sticky: true})
					console.error(xhr)
				}}
				multiple={true}
				onUpload={() => {
					toast ({severity: 'success', summary: 'Upload ok!', detail: 'Refresh the table in the uploaded tab!'})
				}}
				url={application.getApi().uploadUrl}
				onBeforeSend={ ( {xhr, ...e}) => xhr.setRequestHeader("Authorization", `Bearer ${token}`) }
			/>
		</React.Fragment>
	)
}

export default FileUploader
