import React, { useCallback, useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import LoadingButton from '../../../../components/extra/buttons/loading-button';
import ApplicationContext from '../../../../contexts/application-context/application-context';

const DownloadExcelButton = ({idmanifest, label, original, ...props}) => {

	const {getAccessTokenSilently} = useAuth0();
	const {getApi} = useContext(ApplicationContext);

	const getExcel = useCallback (async () => {
		try{
			const token = await getAccessTokenSilently();
			await getApi().getExcelForManifestId(token, idmanifest, !!original)
		} catch (e) {
			console.error (e);
			//TODO: Should I toast here?
		}
	}, [getAccessTokenSilently, idmanifest, getApi, original])

	return !!label
		? <LoadingButton className={'p-button-sm p-button-second'} label={label} icon={'pi pi-file-excel'} execute={getExcel} />
		: <LoadingButton className={'p-button-md p-button-text'} icon={'pi pi-file-excel'} execute={getExcel}/>
}

export default DownloadExcelButton;
