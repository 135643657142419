import axios from 'axios';

export async function authenticatedGet(token, url, params) {
	return await authenticatedFetch(token, url, params);
}

export async function authenticatedPost (token, url, body) {
	return await authenticatedFetch(token, url, {method: 'POST'}, body);
}

export async function authenticatedPut (token, url, parameters, body) {
	return await authenticatedFetch(token, url, {...parameters, method: 'PUT'}, body);
}

export async function authenticatedDelete (token, url, body) {
	return await authenticatedFetch(token, url, {method: 'DELETE'}, body);
}

export async function authenticatedFetch (token, url, params, body) {
	let headers = !!params ? params.headers : {};

	return await axios ({
		url,
		...params,
		headers :{
			...headers,
			Authorization: `Bearer ${token}`
		},
		data: body
	}).catch(e => {
		console.error(e);
		throw e.response.data
	})
}
