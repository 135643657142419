import React, { useCallback, useContext, useState } from 'react';
import LoadingButton from './loading-button';
import { useHistory } from 'react-router-dom';
import ApplicationContext from '../../../contexts/application-context/application-context';


const BackButton = ({ directTo, ...props }) => {
	const [disabled, setDisabled] = useState();
	const history = useHistory()
	const {appGoTo} = useContext(ApplicationContext)

	const onClickFn = useCallback(async () => {
		setDisabled(true);

		if (!! directTo) {
			appGoTo(directTo);
		} else {
			history.goBack();
		}

	}, [setDisabled, directTo, history, appGoTo]);

	return <LoadingButton
		className={'p-button-sm p-button-text p-button-secondary'}
		icon={'pi pi-chevron-left'}
		execute={onClickFn}
		disabled={disabled}
		{...props}
	/>
}

export default BackButton;
