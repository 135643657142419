import React, { useCallback, useContext, useEffect, useState } from 'react';
import ToastContext from '../../../contexts/toast-context';
import { getSmallPackageForManifestIdAndLrn } from '../../../api/manifest';
import { useAuth0 } from '@auth0/auth0-react';
import DataMatrix from '../../../components/extra/data-matrix';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import ConfirmationButton from '../../../components/extra/buttons/confirmation-button';

const addressMatrix = ({name, address, city, country, zipCode}) => {
	return {
		Sender: {
			key: 'name',
			value: name
		},
		Address: {
			key: 'address',
			value: address
		},
		City: {
			key: 'city',
			value: city
		},
		Zipcode:{
			key: 'zipCode',
			value: zipCode
		},
		Country: {
			key: 'country',
			value: country
		}
	}
}

const decMatrix = ({iossNo, grossWeight, netWeight, wayBillNo}) => {
	return {
		IOSS: {
			key: 'iossNo',
			value: iossNo
		},
		NetWeight: {
			key: 'netWeight',
			value: netWeight
		},
		GrossWeight: {
			key: 'grossWeight',
			value: grossWeight
		},
		"Waybill No.": {
			key: 'wayBillNo',
			value: wayBillNo
		}
	}

}

const SmallPackage = ({idManifest, lrn, submitPackage, enableEdit, ...props}) => {
	const [smallPackage, setSmallPackage] = useState(null);
	const [itemList, setItemlist] = useState(null);
	const [loading, setLoading] = useState(null);
	const toast = useContext(ToastContext);
	const {getAccessTokenSilently} = useAuth0();

	const getSmallPackage = useCallback(async () => {
		try {
			const token = await getAccessTokenSilently();
			setLoading(true)
			const smallPackage = await getSmallPackageForManifestIdAndLrn(token, idManifest, lrn);
			setSmallPackage(smallPackage);
			setItemlist(smallPackage.itemList);
		} catch (e){
			console.error (e);
			toast({severity: 'error', summary: 'Could not get package', detail: `ManifestId: ${idManifest}, Cop: ${lrn}`});
		} finally {
			setLoading(false)
		}
	}, [getAccessTokenSilently, setSmallPackage, toast, idManifest, lrn, setItemlist, setLoading]);

	const updateSmallPackage = useCallback(async () => {
		setLoading(true);
		try {
			let sp = {...smallPackage, itemList: itemList};
			submitPackage(idManifest, lrn, sp);
		} catch(e) {
			console.error (e);
			toast({severity: 'error', summary: 'Could not update package', detail: `ManifestId: ${idManifest}, Cop: ${lrn}`});
		} finally {
			setLoading(false)
		}
	}, [toast, idManifest, lrn, smallPackage, itemList, submitPackage]);

	useEffect(getSmallPackage, [getSmallPackage]);

	const onEditValueChange = ({newValue, ...props}) => {
		const il = [...itemList]
		il[props.rowIndex][props.field] = newValue
		setItemlist(il);
	}

	const edit = (options) => <InputText type="text" value={options.value} onChange={e => options.editorCallback(e.target.value)} />;

	return <div>
		<div className={'flex flex-column md:flex-row'}>
			<DataMatrix
				title={'Sender'}
				data={!! smallPackage ? addressMatrix(smallPackage.senderInfo): {}}
				onChange={enableEdit ? (k,v) => setSmallPackage({...smallPackage, senderInfo: {...smallPackage.senderInfo, [k] : v} }) : null }
			/>
			<div className={'my-2 md:my-0 mx-0 md:mx-2'}/>
			<DataMatrix
				title={'Receiver'}
				data={!! smallPackage ? addressMatrix(smallPackage.receiverInfo): {}}
				onChange={ enableEdit ? (k,v) => setSmallPackage({...smallPackage, receiverInfo: {...smallPackage.receiverInfo, [k] : v} }) : null }
			/>
		</div>

		<DataMatrix
			data={!! smallPackage ? decMatrix(smallPackage): {}}
			onChange={ enableEdit ? (k,v) => setSmallPackage({...smallPackage, [k] : v }) : null }
		/>

		<DataTable className={'mt-3'} value={itemList} dataKey={'gnum'} >
			<Column onCellEditComplete={onEditValueChange} header={'Description'} field={'itemName'} editor={enableEdit ? edit : null}/>
			<Column onCellEditComplete={onEditValueChange} header={'Price/item'} field={'price'} editor={enableEdit ? edit : null}/>
			<Column onCellEditComplete={onEditValueChange} header={'QTY'} field={'qty'} editor={enableEdit ? edit : null}/>
			<Column onCellEditComplete={onEditValueChange} header={'totalPrice'} field={'totalPrice'} editor={enableEdit ? edit : null}/>
			<Column onCellEditComplete={onEditValueChange} header={'HS Code'} field={'gcode'} editor={enableEdit ? edit : null}/>
		</DataTable>

		{enableEdit && <div className={'mt-4 text-right'}>
			<ConfirmationButton
				onAccept={updateSmallPackage}
				warningMessage={'This action will declare the package with customs. This action cannot be undone. Do you want to continue?'}
				buttonLabel={'Update and send package to customs'}
				rejectedMessage={'The package was not declared'}
				loading={loading}
				disabled={loading}
			/>
		</div> }
	</div>
}

export default SmallPackage;
