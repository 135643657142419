import React, { useContext, useEffect, useRef } from 'react';
import ApplicationContext from '../../contexts/application-context/application-context';
import XButton from '../extra/buttons/x-button/XButton';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Menu } from 'primereact/menu';
import { NO_APPLICATION_KEY } from '../../contexts/application-context/application-keys';

export const ApplicationSelector = () => {
	let {allApplications, currentApplication, setApplicationContext, clearCurrentApplication, loadingApplications} = useContext(ApplicationContext);
	let overlayRef = useRef(null);
	let isMounted = useRef(false);

	let _applications = [
		...Object.values(allApplications)
			.filter(e => !e.ofType(NO_APPLICATION_KEY))
			.slice(0,3)
			.map(e => ({...e, command: () => setApplicationContext(e.value)})),
		{ separator: true },
		{ label: 'View all', command: clearCurrentApplication }
	]

	useEffect(() => {
		if (isMounted.current && !! overlayRef.current) {
			overlayRef.current.hide();
		}
	})

	useEffect(() => {
		isMounted.current = true
	}, [])

	if (loadingApplications || ! currentApplication){
		return	<XButton loading rounded disabled text icon={'pi-th-large'} />
		// return	<XProgressSpinner />
	}

	return Object.keys(allApplications).length > 2 && <div className={'flex align-items-center mr-2'}>
		<div className={'mr-2'}>Application: { currentApplication.ofType(NO_APPLICATION_KEY)? 'Overview' : currentApplication.label} </div>
		<XButton rounded text icon={'pi-th-large'} tooltip={'Select application'} tooltipOptions={{position: 'bottom'}} onClick={e => overlayRef.current.toggle(e)}/>
		<OverlayPanel dismissable ref={overlayRef}>
			<Menu model={_applications}/>
		</OverlayPanel>
	</div>
}

export default ApplicationSelector;
