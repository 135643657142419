export function mapToURIParams(object) {
	let s = Object.keys(object).reduce((prev, curr) =>  {
		let value = object[curr];
		if (value != null){
			return [...prev, encodeURIComponent(curr) + '=' + encodeURIComponent(object[curr])];
		} else {
			return [...prev]
		}
	}, []);
	return s.join('&');
}
