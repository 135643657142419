import React from 'react';
import { PageableTable } from './PageableTable';
import XProgressSpinner from '../progress-spinner/XProgressSpinner';

export const StandardPageableSearchableSpringBootDataTable = (
	{
		fullRemoteContent,
		column_config,
		setPagesettings,
		pagesettings,
		setSearchSettings,
		onRowSelect,
		loading,
		tableProps,
		...props
	}) => {
	let {content, ...remoteResult} = !!fullRemoteContent && fullRemoteContent;

	return (
		<React.Fragment>
			{/*Deze !! check niet wegdoen, dan triggeren alle calls 2x. Mijn verstand is te klein om te zien waarom. */}
			{!!content ? (
				<PageableTable
					column_config={column_config}
					value={content || []}
					valueFormat={{...remoteResult}}
					tableProps={{
						loading,
						onPageChange: e => {
							setPagesettings({
								...pagesettings,
								page: e.page,
								size: e.rows
							})
						},
						onFilter: e => setSearchSettings(Object.keys(e.filters).reduce((prev, cur) => ({...prev, [cur]:e.filters[cur].value}) ,{})),
						selectionMode: !!onRowSelect && 'single',
						onRowSelect,
						...tableProps
					}}
					{...props}
				/>
			) : loading && <XProgressSpinner />
			}
		</React.Fragment>
	);
}
