import React from 'react';
import NavBar from './components/nav-bar/nav-bar';
import { useAuth0 } from '@auth0/auth0-react';
import { ToastContextProvider } from './contexts/toast-context';
import { ApplicationContextProvider } from './contexts/application-context/application-context';
import AppRouter from './Router';
import { Card } from 'primereact/card';
import Logo2016 from './resources/logo/logo_2016.png'
import LoginButton from './components/nav-bar/login-button';
import XProgressSpinner from './components/extra/progress-spinner/XProgressSpinner';


function App () {
	const { isLoading, isAuthenticated } = useAuth0();
	if (isLoading) {
		return <XProgressSpinner />
	}

	if (!isAuthenticated) {
		return <div className={'flex h-screen justify-content-center align-items-center'} >
			<Card style={{height:'auto', maxWidth:'30em'}}>
				<div align={'center'} className={' flex flex-column '} style={{maxHeight:'30em', width:'auto'}}>
					<img alt={'Crossroad logo'} src={Logo2016} className={''} style={{objectFit: 'contain', overflow: 'hidden' }}/>
					{/*<h2 align={'center'}>Welcome</h2>*/}
					<LoginButton className={'mt-5'}/>
				</div>
			</Card>
		</div>
	}

	return (
		<div id={"app"} className={'flex h-full flex-column'}>
			<ToastContextProvider>
				<ApplicationContextProvider>
					<NavBar/>
					<div className={'container h-full flex-grow-1'}>
						<AppRouter />
					</div>
				</ApplicationContextProvider>
			</ToastContextProvider>
		</div>
	);
}

export default App;
