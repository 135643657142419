import React, { useCallback, useContext, useState } from 'react';
import { ManifestList } from '../common-files/manifest-list/ManifestList';
import { InputText } from 'primereact/inputtext';
import { Divider } from 'primereact/divider';
import ConfirmationButton from '../../components/extra/buttons/confirmation-button';
import { useAuth0 } from '@auth0/auth0-react';
import ToastContext from '../../contexts/toast-context';
import ApplicationContext from '../../contexts/application-context/application-context';

export const NctsApplication = () => {
	const { getAccessTokenSilently } = useAuth0();
	const toast = useContext(ToastContext);
	const {getApi} = useContext(ApplicationContext);

	const [mrn, setMrn] = useState('');

	const declareT1 = useCallback(async () => {
		try {
			const token = await getAccessTokenSilently();
			await getApi().declareManifestForId(token, mrn);
			toast({ severity: 'success', summary: 'T1 clearance requested', detail: mrn })
			setMrn('')
		} catch (e) {
			toast({ severity: 'error', summary: 'Something went wrong', detail: e.message, sticky: true })
			console.error(e)
		}
	}, [getAccessTokenSilently, toast, mrn, getApi, setMrn]);

	return <div>
		<h3>Enter T1 MRN to clear</h3>
		<div className="p-col-12 p-md-4">
			<div className="p-inputgroup">
				<InputText placeholder="T1 MRN" value={mrn} onChange={e => setMrn(e.target.value)}/>
				<ConfirmationButton
					onAccept={declareT1}
					warningMessage={'Did you verify the document is addressed to Cainiao Belgium? \n\r\n This action trigger the clearance of this T1 with customs. This action cannot be undone. \n\n Do you want to continue?'}
					buttonLabel={'Clear T1'}
					icon={'pi pi-angle-right'}
					rejectedMessage={'T1 clearance not sent'}
					className={'p-button'}
				/>
			</div>
		</div>

		<Divider className={'my-5'}/>

		<ManifestList reloadable disableRowSelection title={'Declaration history'}/>
	</div>
}

export default NctsApplication;
