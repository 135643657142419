import React, { useCallback, useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import ConfirmationButton from '../../../../components/extra/buttons/confirmation-button';
import ApplicationContext from '../../../../contexts/application-context/application-context';


const DeleteManifestButton = ({ idmanifest, labeled, onSuccess, ...props }) => {
	const { getAccessTokenSilently } = useAuth0();
	const {getApi} = useContext(ApplicationContext)
	const actionfn = useCallback(async () => {
		try {
			const token = await getAccessTokenSilently();
			await getApi().deleteManifest(token, idmanifest).then(onSuccess);
		} catch (e) {
			console.error(e);
			//TODO: Should I toast here?
		}
	}, [getAccessTokenSilently, idmanifest, onSuccess, getApi])

	return labeled
		? <ConfirmationButton
			className={'p-button-sm p-button-danger'}
			label={'Delete Xlsx'}
			icon={'pi pi-trash'}
			onAccept={actionfn}
			warningMessage={'This action will delete the manifest and uploaded excel. This action cannot be undone. Do you want to continue?'}
			buttonLabel={'Start manifest processing'}
			rejectedMessage={'The manifest was not processed'} />
		: <ConfirmationButton
			className={'p-button-md p-button-text'}
			icon={'pi pi-trash'}
			style={{ color: 'var(--pink-500)' }}
			onAccept={actionfn}
			warningMessage={'This action will delete the manifest and uploaded excel. This action cannot be undone. Do you want to continue?'}
			rejectedMessage={'The manifest was not processed'} />
}

export default DeleteManifestButton;
