import React, { useCallback, useEffect, useState } from 'react';
import LoadingButton from './loading-button';


const RefreshButton = ({ onClick, timeoutPeriod = 0, ...props }) => {
	const [isStillMounted, setMounted] = useState();
	const [disabled, setDisabled] = useState();

	const onClickFn = useCallback(async () => {
		setDisabled(true);
		await onClick();
		if (isStillMounted) {
			setTimeout(() => setDisabled(false), timeoutPeriod)
		}
	}, [setDisabled, isStillMounted, onClick, timeoutPeriod]);

	useEffect(() => {
		setMounted(true);
		return () => setMounted(false);
	}, [setMounted])

	return <LoadingButton
		className={'p-button-md p-button-text p-button-secondary'}
		icon={'pi pi-refresh'}
		execute={onClickFn}
		disabled={disabled}
		{...props}
	/>
}

export default RefreshButton;
