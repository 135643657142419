import React from 'react';
import { Timeline } from 'primereact/timeline';
import XMoment from '../../../../components/extra/x-moment/x-moment';


const ManifestTimeline = ({ manifest, ...props }) => {

	let {
		userApproved,
		userApprovedAt,
		userApprovedBy,
		markedDoneAt,
		markedDoneBy,
		done,
		processed,
		isProcessing,
		sent,
		isSending,
		sentAt,
		sentBy
	} = manifest;

	const events = [
		{
			eventTitle: 'Approved',
			hasHappened: userApproved,
			triggeredAt: userApprovedAt,
			triggeredBy: userApprovedBy
		},
		{
			eventTitle: 'Processed',
			hasHappened: processed,
			isHappening: !processed && isProcessing,
			triggeredAt: '',
			triggeredBy: ''
		},
		{
			eventTitle: 'Sent',
			hasHappened: sent,
			isHappening: !sent && isSending,
			triggeredAt: sentAt,
			triggeredBy: sentBy
		},
		{
			eventTitle: 'Done',
			hasHappened: done,
			triggeredAt: markedDoneAt,
			triggeredBy: markedDoneBy
		}

	]

	return (<div style={{flexBasis: 0, flexGrow: '1'}}>
		<Timeline
			className={'text-left'}
			value={events}
			align={'left'}
			opposite={({eventTitle}) => <div className={'text-bold'}>{eventTitle}</div>}
			marker={({ hasHappened, isHappening=false }) => {
				return <React.Fragment>
					{ hasHappened && !isHappening && <i className={'pi pi-check-circle'} style={{color: 'var(--green-500)'}} />}
					{ !hasHappened && isHappening && <i className={'pi pi-spin pi-spinner'} style={{color: 'var(--grey-500)'}} />}
					{ !hasHappened && !isHappening && <i className={'pi pi-times-circle'} style={{color: 'var(--pink-500)'}} />}
				</React.Fragment>

			}}

			content={({hasHappened, triggeredAt, triggeredBy}) => {
				return <div>
					{hasHappened && (
						<React.Fragment>
							{!!triggeredAt &&<div className={'text-light mb-1'}><i className={'pi pi-clock mr-2'}/><XMoment format="DD/MM/YYYY HH:mm:ss" date={triggeredAt}/></div>}
							{!!triggeredBy &&<div className={'text-light'}><i className={'pi pi-user mr-2'}/>{triggeredBy}</div>}
						</React.Fragment>
					)}
				</div>
			}}/>
	</div>)

}

export default ManifestTimeline;
