import React from 'react';
import { Inplace, InplaceContent, InplaceDisplay } from 'primereact/inplace';
import { InputText } from 'primereact/inputtext';

const DataMatrix = ({data, title, onChange, ...props}) => {
	return <div className={'flex-1'}>
		<div className={'p-datatable p-datatable-sm'} style={{tableLayout:'auto'}}>
			{!!title && <div className={'p-datatable-header'}>{title}</div>}
			<div className={'p-datatable-wrapper'}>
				<table role={'grid'}>
					<tbody className={'p-datatable-tbody'}>
					{ Object.keys(data).map(key => <tr key={key}>
						<td style={{maxWidth: '25%'}}>{key}</td>
						<td className={'text-right'}>
							{!!onChange ? <Inplace>
								<InplaceDisplay>{!!data[key].value ? data[key].value : <span>Edit value<i className={'pi pi-pencil ml-1'}/></span>}</InplaceDisplay>
								<InplaceContent>
									<InputText className={'p-inputtext-sm overflow-hidden text-overflow-ellipsis'} value={data[key].value} onChange={e => onChange(data[key].key, e.target.value) } />
								</InplaceContent>
							</Inplace> : typeof data[key] ==='object' ? data[key].value : data[key] }
						</td>
					</tr>) }
					</tbody>
				</table>
			</div>
		</div>
	</div>
}

export default DataMatrix;
