import React from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import ProtectedRoute from '../../auth/protected-route';
import { Manifest } from './manifest/detail/manifest';
import { ManifestList } from '../common-files/manifest-list/ManifestList';
import HomePage from './homepage/home';

const ManifestApplicationRouter = () => {
	let {path} = useRouteMatch()
	return <Switch>
		<ProtectedRoute path={`${path}/manifest/:manifestId`} component={Manifest}/>
		<ProtectedRoute path={`${path}/manifest`} component={ManifestList}/>
		<ProtectedRoute path={`${path}/:indexId`} component={HomePage}/>}
		<ProtectedRoute exact path={`${path}`} component={HomePage}/>
	</Switch>
}

export default ManifestApplicationRouter;
