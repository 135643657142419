import React from 'react';
import { Tree } from 'primereact/tree';
import data from '../../../resources/test/data.json';

export const XTree = ({...props}) => {
	return <Tree {...props} value={data}>

	</Tree>
}

export default XTree;
