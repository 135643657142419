import React from "react";
import { Route } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import XProgressSpinner from '../components/extra/progress-spinner/XProgressSpinner';


const ProtectedRoute = ({ component, ...args }) => (
	<Route
		component={withAuthenticationRequired(component, {
			onRedirecting: () => <XProgressSpinner/>
		})}
		{...args}
	/>
);

export default ProtectedRoute;
