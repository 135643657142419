import { authenticatedDelete, authenticatedGet, authenticatedPost, authenticatedPut } from './authenticated-api';
import { mapToURIParams } from './util';


export const uploadUrl = process.env.REACT_APP_SERVER_URL + process.env.REACT_APP_MANIFEST_ENDPOINT + '/all';


export async function getAllManifests (token, pageable, searchable) {
	let url = `${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_MANIFEST_ENDPOINT}/all?${mapToURIParams(pageable)}&${mapToURIParams(searchable)}`
	return await authenticatedGet(token, url).then(({data}) => data.results);
}

export async function getAllManifestsForProcessedSentDone (token, processed, sent, done, blocked, pageable, searchable) {
	const queryParams = mapToURIParams(pageable);
	const searchParams = mapToURIParams(searchable);
	const detailParams = mapToURIParams({processed, sent, done, blocked})
	let url = `${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_MANIFEST_ENDPOINT}/all?${detailParams}&${queryParams}&${searchParams}`
	return await authenticatedGet(token, url).then(({data}) => data.results);
}

export async function getManifestWithId (token, id, pageable, withError, withPending, withBlocked) {
	const queryParams = mapToURIParams(pageable);
	const mentionParams = mapToURIParams({ withError, withPending, withBlocked })
	let url = `${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_MANIFEST_ENDPOINT}/${id}?${queryParams}&${mentionParams}`
	return await authenticatedGet(token, url).then(({data}) => data.results[0]);
}

export async function getSmallPackageForManifestIdAndLrn (token, id, lrn){
	let url = `${process.env.REACT_APP_SERVER_URL}/applicationh7/packagetocorrect?manifestId=${id}&lrn=${lrn}`;
	return authenticatedGet(token, url).then(({data}) => JSON.parse(data.results[0]));
}

export async function updateSmallPackageForManifestIdAndLrn (token, id, lrn, body){
	let url = `${process.env.REACT_APP_SERVER_URL}/applicationh7/packagetocorrect?manifestId=${id}&lrn=${lrn}`;
	return authenticatedPost(token, url, body);
}

export async function updateManifestWithId (token, id, manifest) {
	let url = `${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_MANIFEST_ENDPOINT}/${id}`
	return await authenticatedPut(token, url, {headers: {'Content-Type': 'application/json'}}, JSON.stringify(manifest)).then(({data}) => data.results[0]);
}

export async function deleteManifest (token, id) {
	let url = `${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_MANIFEST_ENDPOINT}/${id}`
	return await authenticatedDelete(token, url);
}

export async function processManifestForId (token, id) {
	let url = `${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_MANIFEST_ENDPOINT}/${id}/process`
	return await authenticatedPost(token, url);
}

export async function declareManifestForId (token, id, mention) {
	let url = `${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_MANIFEST_ENDPOINT}/${id}/declare/${!!mention ? '?mention=' + mention : ''}`
	return await authenticatedPost(token, url);
}

export async function pullForCorrelationId (token, manifestId, correlationId) {
	let url = `${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_MANIFEST_ENDPOINT}/${manifestId}/gapfiller${!! correlationId && '?correlationId='+correlationId}`
	return await authenticatedGet(token, url);
}

export async function getExcelForManifestId (token, id, original) {
	let url = `${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_MANIFEST_ENDPOINT}/xlsx/${id}?original=${original}`

	const response = await authenticatedGet(token, url, {responseType: 'blob'});

	const contentDisposition = response.headers['content-disposition'];
	const filename = !! contentDisposition
		? contentDisposition.split("filename=")[1].replaceAll('"', '')
		: `mani_${id}_result.xlsx`

	const urlDL = window.URL.createObjectURL(
		new Blob([response.data]),
	);
	const link = document.createElement('a');
	link.href = urlDL;
	link.setAttribute(
		'download',
		filename,
	);

	// Append to html link element page
	document.body.appendChild(link);

	// Start download
	link.click();

	// Clean up and remove the link
	link.parentNode.removeChild(link);
}
