import React, { useContext, useEffect, useState } from 'react';
import FileUploader from './file-uploader';
import { TabMenu } from 'primereact/tabmenu';
import ManifestSummary from './manifest-summary';
import ApplicationContext from '../../../contexts/application-context/application-context';
import { H7_DECLARE } from '../../../contexts/application-context/application-keys';

const HomePage = (props) => {
	const indexId = parseInt(props.match.params.indexId);
	const [tabIndex, setTabIndex] = useState((!!indexId && indexId) || 0);

	const {currentApplication, appGoTo} = useContext(ApplicationContext);

	useEffect(() => {
		if (!!indexId){
			setTabIndex(indexId);
		}
	}, [indexId, tabIndex, setTabIndex])

	const tabModel = [
		{label: 'Uploaded'},
		{label: 'Processed'},
		{label: 'Sent and pending'},
		currentApplication.ofType(H7_DECLARE) && {label: 'Selected for control'}
	]

	const renderSwitch = index => {
		switch (index) {

			case 1:
				return <ManifestSummary
					header={'Not yet sent'}
					processed
					keys={['copid', 'awb', 'portcode', 'parcelcount', 'lastupdate', 'downloadButton']}
					withDownloadButton
				/>
			case 2:
				return <ManifestSummary
					header={'Pending'}
					processed sent
					keys={['copid', 'awb', 'portcode', 'parcelcount', 'sentAt', 'lastupdate', 'downloadButton']}
					withDownloadButton
				/>
			case 3:
				return <ManifestSummary
					header={'Selected for control'}
					blocked
					keys={['mrn', 'copid', 'ie460received', 'correlationid']}
				/>
			default:
				return <ManifestSummary
					header={'Not yet processed'}
					keys={['copid', 'awb', 'portcode', 'eta', 'originalFilename', 'deleteButton']}
					withDeleteButton
				/>
		}
	}

	return (<div >
		<FileUploader />
		<TabMenu className={'mt-2'} model={tabModel} activeIndex={tabIndex} onTabChange={e => appGoTo(`/${e.index}`)}/>
		{ renderSwitch(tabIndex) }
	</div>)
}

export default HomePage;
