import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import ManifestDetailPanel from './manifest-detail-panel';
import FilenameForm from './filename-form';
import { Panel } from 'primereact/panel';
import ToastContext from '../../../../contexts/toast-context';
import SmallPackage from '../../small-package/small-package';
import { Dialog } from 'primereact/dialog';
import { TabbedList } from './tabbed-list';
import { TabMenu } from 'primereact/tabmenu';
import ApplicationContext from '../../../../contexts/application-context/application-context';
import { StandardPageableSearchableSpringBootDataTable } from '../../../../components/extra/DataTableWrappers/StandardPageableSearchableSpringBootDataTable';
import WarningBanner from '../../../../components/extra/warning-banner/WarningBanner';
import { BEGATE_DECLARE } from '../../../../contexts/application-context/application-keys';


export const Manifest = (props) => {
	const {getApi, currentApplication, defaultPageSettings} = useContext(ApplicationContext)
	const toast = useContext(ToastContext);
	const { getAccessTokenSilently } = useAuth0();

	const [manifest, setManifest] = useState(null);
	const [resultItems, setResultItems] = useState([]);
	const [dialogContext, setDialogContext] = useState({visible: false});
	const [tabIndex, setTabIndex] = useState(0);

	const [loading, setLoading] = useState(false)
	const [pagesettings, setPagesettings] = useState ({ ...defaultPageSettings })

	const manifestId = props.match.params.manifestId

	const getManifestDetails = useCallback( async () => {
		try {
			setLoading(true);
			const token = await getAccessTokenSilently();
			let withError = tabIndex === 0;
			let withPending = tabIndex === 1;
			let withBlocked = tabIndex === 2;
			let manifestResponse = await getApi().getManifestWithId(token, manifestId, pagesettings, withError, withPending, withBlocked)
			setManifest(manifestResponse);
			!! manifestResponse.results && setResultItems(manifestResponse.results)
			setLoading(false)
		} catch (error) {
			toast ({severity: 'error', summary: 'Could not get info for this manifest', detail: error.message})
			console.error(error)
		}
	}, [getAccessTokenSilently, manifestId, toast, tabIndex, pagesettings, setResultItems, setManifest, getApi, setLoading])

	const updateManifest = useCallback( async (toUpdateManifest) => {
		try {
			setLoading(true);
			const token = await getAccessTokenSilently()
			const updatedManifest = await getApi().updateManifestWithId(token, manifestId, toUpdateManifest);
			setManifest({ ...manifest, manifest:updatedManifest })
			setLoading(false);
		} catch (error) {
			toast ({ severity: 'error', summary: 'Could not update the manifest', detail: error.message, sticky: true })
			console.error(error)
		}

	}, [manifestId, manifest, getAccessTokenSilently, toast, getApi, setLoading]);

	const updateSmallPackage = useCallback(async (idManifest, idCop, smallPackage) => {
		try {
			setLoading(true);
			const token = await getAccessTokenSilently()
			await getApi().updateSmallPackageForManifestIdAndLrn(token, idManifest, idCop, smallPackage).then(getManifestDetails);
			setLoading(false);
		} catch(e) {
			console.error (e)
			toast({severity: 'error', summary: 'Could not update package', detail: `ManifestId: ${idManifest}, Cop: ${idCop}`})
		}

		setDialogContext({visible: false})
	}, [getAccessTokenSilently, toast, setDialogContext, getManifestDetails, getApi, setLoading]);

	const pullItem = useCallback( async (correlationId) => {
		try {
			const token = await getAccessTokenSilently();
			await getApi().pullForCorrelationId(token, manifestId, correlationId).then(getManifestDetails)
		} catch (error) {
			console.error(error);
			toast ({severity: 'error', summary: 'Could not get info for this manifest', detail: error.message});
		}
	}, [getAccessTokenSilently, toast, getManifestDetails, getApi, manifestId])

	useEffect (getManifestDetails, [getManifestDetails, tabIndex, currentApplication, getApi] )

	const showDialogForRow = (row, enableEdit = false) => {
		setDialogContext({
			visible: true,
			smallPackage: row,
			enableEdit
		})
	}

	return (<div>
		{!!manifest && manifest.manifest.userApproved && <ManifestDetailPanel manifest={manifest} refresh={getManifestDetails}/> }
		{!!manifest &&
			!manifest.manifest.userApproved &&
			<Panel header={manifest.manifest.originalFilename}>
				<FilenameForm onSubmit={e => {
					updateManifest({...e, userApproved: true });
				}} manifest={manifest.manifest}/>
			</Panel>
		}

		{ currentApplication.ofType(BEGATE_DECLARE) ?
			!!resultItems && !!resultItems.content && resultItems.content.length > 0 && <React.Fragment>
				<WarningBanner/>
				<StandardPageableSearchableSpringBootDataTable
					column_config={[{field: 'uniqueNumber', header: 'Parcel Number'}, {field: 'error', header: 'Error'}]}
					fullRemoteContent={resultItems}
					setPagesettings={setPagesettings}
					loading={loading}/>
			</React.Fragment>
			:
			<React.Fragment>
				<TabMenu className={'my-2'} model={[{label: 'Error'}, {label:'Pending'},{label:'Selected for control'}]} activeIndex={tabIndex} onTabChange={e => {
					e.index !== tabIndex && setResultItems([]);
					setPagesettings(defaultPageSettings);
					setTabIndex(e.index);
				}}/>
				<TabbedList
					tabIndex={tabIndex}
					items={resultItems}
					showDialogForRow={showDialogForRow}
					updateSmallPackage={updateSmallPackage}
					pullItem={pullItem}
					loading={loading}
					setPagesettings={setPagesettings}
				/>
			</React.Fragment>
		}

		<Dialog blockScroll visible={dialogContext.visible} header={!!dialogContext.smallPackage && dialogContext.smallPackage.lrn} style={{width: '75vw'}} onHide={() => setDialogContext({visible: false})}>
			{
				<React.Fragment>
					{dialogContext.visible && !!dialogContext.smallPackage && !!dialogContext.smallPackage.errormessage &&
							<WarningBanner warning={dialogContext.smallPackage.errormessage}/>
					}

					{dialogContext.visible && <SmallPackage
						enableEdit={dialogContext.enableEdit}
						idManifest={dialogContext.smallPackage.manifestid}
						lrn={dialogContext.smallPackage.lrn}
						submitPackage={updateSmallPackage}
					/>}

				</React.Fragment>
			}
		</Dialog>
	</div>)

}
