import React, { useCallback, useContext, useState } from 'react';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import DownloadExcelButton from './download-excel-button';
import ConfirmationButton from '../../../../components/extra/buttons/confirmation-button';
import { useAuth0 } from '@auth0/auth0-react';
import ToastContext from '../../../../contexts/toast-context';
import ApplicationContext from '../../../../contexts/application-context/application-context';


const ActionButtons = ({ manifest, refreshManifest, ...props }) => {
	const [withSpecialMention, setWithSpecialMention] = useState(false);
	const [mention, setMention] = useState('');
	const { getAccessTokenSilently } = useAuth0();
	const toast = useContext(ToastContext);
	const { getApi} = useContext(ApplicationContext);

	const { sent, processed, isSending, isProcessing, done, idmanifest, copid } = manifest;

	const processManifest = useCallback(async () => {
		try {
			const token = await getAccessTokenSilently();
			await getApi().processManifestForId(token, idmanifest);
			await refreshManifest();
			toast({ severity: 'success', summary: 'Manifest processed', detail: copid })
		} catch (e) {
			toast({ severity: 'error', summary: 'Could not process manifest', detail: e.message, sticky: true })
			console.error(e)
		}
	}, [getAccessTokenSilently, idmanifest, toast, refreshManifest, copid, getApi]);

	const declareManifest = useCallback(async () => {
		try {
			const token = await getAccessTokenSilently();
			await getApi().declareManifestForId(token, idmanifest, mention);
			await refreshManifest();
			toast({ severity: 'success', summary: 'Manifest declared', detail: copid })
		} catch (e) {
			toast({ severity: 'error', summary: 'Could not declare manifest', detail: e.message, sticky: true })
			console.error(e)
		}
	}, [getAccessTokenSilently, idmanifest, mention, toast, refreshManifest, copid, getApi]);

	const finishManifest = useCallback(async () => {
		try {
			const token = await getAccessTokenSilently();
			await getApi().updateManifestWithId(token, idmanifest, { done: true })
			await refreshManifest();
			toast({ severity: 'success', summary: 'Manifest marked as done', detail: copid })
		} catch (e) {
			toast({ severity: 'error', summary: 'Could not mark manifest as finished', detail: e.message, sticky: true })
			console.error(e)
		}
	}, [getAccessTokenSilently, idmanifest, toast, refreshManifest, copid, getApi]);

	return (
		<React.Fragment>
			<div className={'flex sm:justify-content-between sm:flex-row flex-column mt-4 sm:align-items-end align-items-center align-items-stretch'}>
				<div className={'flex flex-column sm:mb-0 mb-2'}>
					<DownloadExcelButton label={'Download original upload'} idmanifest={idmanifest} original />
					<div className={'my-1'}/>
					{ sent && <DownloadExcelButton label={'Download results xlsx'} idmanifest={idmanifest}/> }
				</div>

				{!processed && !sent && !done && <ConfirmationButton
					onAccept={processManifest}
					warningMessage={'This action will process the excel for this manifest. This action cannot be undone. Do you want to continue?'}
					buttonLabel={'Start manifest processing'}
					rejectedMessage={'The manifest was not processed'}
					loading={!processed && isProcessing}
					skipAwait
				/>}

				{processed && !sent && !done && <div>
					<form className={'p-fluid mb-2'}>
						<div className={'flex justify-content-between mb-2'}>
							<label>With special mention</label>
							<Checkbox checked={withSpecialMention}
									  onChange={e => setWithSpecialMention(e.checked)}/>
						</div>
						{
							withSpecialMention &&
							<InputText value={mention} onChange={e => setMention(e.target.value)}/>
						}
					</form>
					<ConfirmationButton
						onAccept={declareManifest}
						warningMessage={'This action will declare the packages for this manifest with customs. This action cannot be undone. Do you want to continue?'}
						buttonLabel={'Send manifest to customs'}
						rejectedMessage={'The manifest was not declared'}
						loading={!sent && isSending}
						skipAwait
					/>
				</div>}

				{processed && sent && !done && <ConfirmationButton
					onAccept={finishManifest}
					buttonLabel={'Mark manifest as done'}
					warningMessage={'This action will mark the manifest as finished. This action cannot be undone. Do you want to continue?'}
					rejectedMessage={'The manifest was not marked as finished'}
				/>}

				{processed && sent && done && <div className={'text-center'}> Manifest marked as done. No more actions can be taken. </div>}
			</div>
		</React.Fragment>
	)
}

export default ActionButtons;
