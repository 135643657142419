import React, { useContext, useState } from 'react';
import { Calendar } from 'primereact/calendar';
import { SelectButton } from 'primereact/selectbutton';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import ApplicationContext from '../../../../contexts/application-context/application-context';
import { BEGATE_DECLARE, H7_DECLARE } from '../../../../contexts/application-context/application-keys';

const FilenameForm = ({onSubmit, manifest, ...props}) => {
	const [awb, setAwb] = useState( !!manifest ? manifest.awb : '');
	const [copid, setCopid] = useState( !!manifest ? manifest.copid : '');
	const [eta, setEta] = useState( !!manifest ? new Date(manifest.eta) : new Date());
	const [portcode, setPort] = useState( !!manifest ? manifest.portcode : '');
	const {currentApplication} = useContext(ApplicationContext);

	const fields = {
		[H7_DECLARE] : {
			form: [
				{
					key: 1,
					label: 'AWB',
					field: <InputText value={awb} onChange={e => setAwb(e.target.value)}/>
				},{
					key: 2,
					label: 'ETA',
					field: <Calendar value={eta} dateFormat={'yy-mm-dd'} onChange={(e) => {
						setEta(e.value)
					}} showTime showSeconds />
				}, {
					key: 3,
					label: 'Port',
					field: <SelectButton value={portcode} onChange={e => setPort(e.value)} options={['BRU', 'LGG']} />
				}
			],
			onSubmit: {awb, eta, portcode}
		},

		[BEGATE_DECLARE] : {
			form: [{
				key: 1,
				label: 'Manifest',
				field: <InputText value={copid} onChange={e => setCopid(e.target.value)}/>
			},{
				key: 2,
				label: 'ETA',
				field: <Calendar value={eta} dateFormat={'yy-mm-dd'} onChange={(e) => {
					setEta(e.value)
				}} showTime showSeconds />
			}],
			onSubmit: {copid, eta}
		}
	}

	return <React.Fragment>
		<form className={'p-fluid'} onSubmit={e => {
			e.preventDefault();
			onSubmit (fields[currentApplication.applicationType].onSubmit)
		}}>
			{ (fields[currentApplication.applicationType].form).map(e => {
				return <div key={e.key} className={'field grid'}>
					<label className={'col-12 md-2'}>{e.label}</label>
					<div className={'col-12 md-10'}>
						{e.field}
					</div>
				</div>

			})}

			<div className={'flex'}>
				<Button className={'p-button-sm p-button-text'} label={'Cancel'} icon={'pi pi-times'} />
				<Button type='submit' className={'p-button-sm p-button-text'} label={'Ok'} icon={'pi pi-check'} />
			</div>
		</form>
	</React.Fragment>

}

export default FilenameForm;
