import React, { createContext, useRef } from 'react';
import { Toast } from 'primereact/toast';

const ToastContext = createContext();

export default ToastContext;

export function ToastContextProvider ({children}) {
	const toast = useRef(null);

	const doToast = (obj) => {
		toast.current.show ({
			...obj
		})
	}

	return <ToastContext.Provider value={doToast} >
		<Toast ref={toast} position={'bottom-right'}/>
		{children}
	</ToastContext.Provider>
}
