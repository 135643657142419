import React from 'react';
import { NO_APPLICATION_KEY } from './application-keys'

export const noApplication = {
	label: 'No applications available',
	value: NO_APPLICATION_KEY,
	applicationType: NO_APPLICATION_KEY,
	uuid: 'noApplication',
	//faking to check type
	ofType: e => NO_APPLICATION_KEY === e
}

export const NoApplicationApplication = () => {
	return <div>No application available</div>
}
