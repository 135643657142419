import React from 'react';
import { Panel } from 'primereact/panel';
import RefreshButton from '../../../../components/extra/buttons/refresh-button';
import ActionButtons from '../buttons/action-buttons';
import ManifestTimeline from './timeline';
import BackButton from '../../../../components/extra/buttons/back-button';
import { Divider } from 'primereact/divider';
import DataMatrix from '../../../../components/extra/data-matrix';
import XMoment from '../../../../components/extra/x-moment/x-moment';

const packageDetailMatrix = ({totalReleaseCount, totalBlockedCount, totalPendingCount, totalErrorCount, totalCount}) => {
	return {
		Released : !!totalReleaseCount ? totalReleaseCount : 0,
		Error: totalErrorCount,
		Blocked: !!totalBlockedCount ? totalBlockedCount : 0,
		Pending: totalPendingCount,
		Total: totalCount
	}
}

const ManifestDetailPanel = ({manifest, refresh, ...props}) => {

	const { copid, awb, parcelcount, tspInfo } = manifest.manifest

	const headerTemplate = (
		<div className={'p-panel-header flex justify-content-between'}>
			<div className={'flex align-items-center'}>
				<BackButton />
				<Divider layout={'vertical'} align={'center'}/>
				<div className={'p-panel-title'}>{`${copid} ${!!awb ? '- '+ awb : ''}`}</div>
				{ !!parcelcount && <React.Fragment>
					<Divider layout={'vertical'} align={'center'}/>
					<div className={'p-panel-title'}>{parcelcount} parcels</div>
				</React.Fragment> }
			</div>
			<div className={'flex align-items-center'}>
				<div className={'mr-1'}> {!! tspInfo && tspInfo} </div>
				<RefreshButton timeoutPeriod={10000} onClick={refresh}/>
			</div>
		</div>
	)

	return (
		<Panel headerTemplate={headerTemplate}>
			<div className={'flex flex-column md:flex-row justify-content-between'}>
				<ManifestTimeline manifest={manifest.manifest} />
				<div className={'my-2 md:my-0 mx-0 md:mx-2'}/>
				<DataMatrix title={
					<div className={'flex justify-content-between'}>
						<span>Declarations</span>
						<div className={'flex justify-content-end'}>
							<span className={'mr-2'}>Last updated: </span>
							<XMoment  format="DD/MM/YYYY HH:mm:ss" date={manifest.manifest.lastupdate} />
						</div>
					</div>
				} data={packageDetailMatrix(manifest)}/>
			</div>
			<ActionButtons manifest={manifest.manifest} refreshManifest={refresh} />
		</Panel>
	)
}


export default ManifestDetailPanel;
