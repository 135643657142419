import React, { useCallback, useContext, useEffect, useState } from 'react';
import { confirmDialog } from 'primereact/confirmdialog';
import { Button } from 'primereact/button';
import ToastContext from '../../../contexts/toast-context';

const ConfirmationButton = ({onAccept, buttonLabel, warningMessage, rejectedMessage, loading: isLoading, disabled: isDisabled, skipAwait, ...props}) => {
	const [isStillMounted, setMounted] = useState(null)
	const [loading, setLoading] = useState(isLoading);
	const [disabled, setDisabled] = useState(isDisabled);
	const toast = useContext(ToastContext);

	const performOnAccept = useCallback(async() => {
		setDisabled(true);
		if (!!skipAwait){
			onAccept();
		} else {
			await onAccept();
		}
		if (isStillMounted && !isLoading && !skipAwait) {
			setLoading(false)
			setDisabled(false);
		}

	}, [setLoading, setDisabled, onAccept, isStillMounted, isLoading, skipAwait])

	const confirm = async () => {
		confirmDialog({
			icon: 'pi pi-exclamation-triangle',
			header: 'Pay attention!',
			message: warningMessage,
			accept: performOnAccept,
			acceptClassName: 'p-button-warning',
			reject: () => {
				setLoading(false);
				toast ({severity: 'warn', summary: 'Action canceled', detail: rejectedMessage})
			},
			onHide: e => {
				if (!e) {
					setLoading(false)
				}
			}
		})
	}

	useEffect(() => {
		setLoading(isLoading);
		setMounted(true)
		return () => setMounted(false);
	}, [isStillMounted, setMounted, isLoading, setLoading])

	return (
		<React.Fragment>
		<Button className={'p-button-sm p-button-raised p-button-text'}
				disabled={disabled}
				label={buttonLabel}
				loading={loading}
				onClick={async () => {
					setLoading(true);
					await confirm();
				}}
				{...props}
		/>
			{/*<ConfirmDialog/>*/}
		</React.Fragment>

	)
}

export default ConfirmationButton;
