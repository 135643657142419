import React from 'react';
import RefreshButton from '../../../../components/extra/buttons/refresh-button';
import {
	StandardPageableSearchableSpringBootDataTable
} from '../../../../components/extra/DataTableWrappers/StandardPageableSearchableSpringBootDataTable';
import WarningBanner from '../../../../components/extra/warning-banner/WarningBanner';
import XMoment from '../../../../components/extra/x-moment/x-moment';

export const TabbedList = ({ tabIndex, items, updateSmallPackage, pullItem, ...props}) => {
	const renderSwitch = () => {
		switch (tabIndex) {
			case 2: return <ControlTable items={items} {...props}/>
			case 1: return <PendingTable items={items} pullItem={pullItem} {...props}/>
			default: return <ErrorTable items={items} {...props}/>

		}
	}

	return <React.Fragment> {renderSwitch()} </React.Fragment>
}

export default TabbedList;

const errorConfig = [
	{ 'field': 'lrn', 'header': 'LRN' },
	{ 'field': 'errormessage', 'header': 'Error message' },
]

const ErrorTable = ({showDialogForRow, items, setPagesettings, loading}) => {
	return <React.Fragment>
		{items.totalElements > 0 && <WarningBanner/>}
		<StandardPageableSearchableSpringBootDataTable
			column_config={errorConfig}
			fullRemoteContent={items}
			setPagesettings={setPagesettings}
			loading={loading}
			onRowSelect={({data}) => showDialogForRow(data, true /*enableEdit*/)}/>
	</React.Fragment>
}

const pendingConfig = (pullItem) => [
	{ 'field': 'lrn', 'header': 'LRN' },
	{ 'field': 'mrn', 'header': 'MRN' },
	{ 'field': 'ie428received', 'header': 'MRN received at', body: row => !!row.ie428received && <XMoment stacked={true} date={row.ie428received}/> },
	{ 'field': 'actions', body: row => <div className={'text-right'}>
			<RefreshButton
				className={'p-button-md p-button-text'}
				icon={'pi pi-refresh'}
				execute={() => pullItem(row.correlationid)}
				timeoutPeriod={10000}
			/>
		</div>
	},
]

const PendingTable = ({items, pullItem, setPagesettings, loading, showDialogForRow}) => {
	return <StandardPageableSearchableSpringBootDataTable
		column_config={pendingConfig(pullItem)}
		fullRemoteContent={items}
		setPagesettings={setPagesettings}
		loading={loading}
		onRowSelect={({data}) => showDialogForRow(data)}
		/>
}

const controlConfig = [
	{ 'field': 'lrn', 'header': 'LRN' },
	{ 'field': 'mrn', 'header': 'MRN' },
	{ 'field': 'ie428received', 'header': 'MRN received at', body: row => !!row.ie428received && <XMoment stacked={true} date={row.ie428received}/> },
	{ 'field': 'ie460received', 'header': 'Control decision at', body: row => !!row.ie460received && <XMoment stacked={true} date={row.ie460received}/> },
	{ 'field': 'ie451received', 'header': 'No release decision at', body: row => !!row.ie451received && <XMoment stacked={true} date={row.ie451received}/> },
]

const ControlTable = ({items, setPagesettings, loading, showDialogForRow}) => {
	return <StandardPageableSearchableSpringBootDataTable
		column_config={controlConfig}
		fullRemoteContent={items}
		setPagesettings={setPagesettings}
		loading={loading}
		onRowSelect={({data}) => showDialogForRow(data)}
	/>
}
