import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import DownloadExcelButton from '../manifest/buttons/download-excel-button';
import RefreshButton from '../../../components/extra/buttons/refresh-button';
import DeleteManifestButton from '../manifest/buttons/delete-manifest-button';
import ToastContext from '../../../contexts/toast-context';
import ApplicationContext from '../../../contexts/application-context/application-context';
import { StandardPageableSearchableSpringBootDataTable } from '../../../components/extra/DataTableWrappers/StandardPageableSearchableSpringBootDataTable';
import XMoment from '../../../components/extra/x-moment/x-moment';
import { BEGATE_DECLARE } from '../../../contexts/application-context/application-keys';


const ManifestSummary = ({ header, withDownloadButton, withDeleteButton, keys, done = false, sent = false , processed = false, blocked = false, ...props }) => {
	const [manifests, setManifests] = useState();
	const [loading, setLoading] = useState(false);
	const [pagesettings, setPagesettings] = useState({
		page:0,
		size: 10,
		sort: blocked ? 'ie460received,desc': 'arrivedon,desc'
	});
	const [searchSettings, setSearchSettings] = useState({});

	const { getAccessTokenSilently } = useAuth0();
	const toast = useContext(ToastContext);
	const {currentApplication, getApi, appGoTo} = useContext(ApplicationContext);

	const getManifests = useCallback( async () => {
		try {
			setLoading(true);
			const token = await getAccessTokenSilently();
			setManifests(await getApi().getAllManifestsForProcessedSentDone(token, processed, sent, done, blocked, pagesettings, searchSettings));
			setLoading(false);
		} catch (e) {
			toast({ severity: 'error', summary: 'Could not get fetch', detail: e.message })
			console.error(e)
		}
	}, [getAccessTokenSilently, toast, processed, sent, done, blocked, getApi, setLoading, pagesettings, searchSettings]);

	useEffect(getManifests, [getManifests, currentApplication])

	const headerTemplate = (
		<div className={'table-header flex justify-content-between align-items-center'}>
			{header}
			<RefreshButton timeoutPeriod={5000} onClick={getManifests}/>
		</div>
	)

	const isBegateApplication = currentApplication.ofType(BEGATE_DECLARE)

	const columnHeaders = {
		'copid': { header: 'copId', filter: !blocked, filterPlaceholder: 'Search by ID' },
		...(!isBegateApplication && {'awb': { header: 'AWB', filter: true, filterPlaceholder: 'Search by AWB' }}),
		'portcode': { header: 'Airport' },
		'parcelcount': {header: 'Parcels' },
		'correlationid': {header: 'Correlation ID' },
		'mrn': {header: 'MRN' },
		'eta': { header: 'ETA', body:row => <XMoment stacked date={row.eta} /> },
		'lastupdate': { header: 'Last update', body:row => <XMoment stacked date={row.lastupdate} /> },
		'sentAt': { header: 'Sent at', body:row => <XMoment stacked date={row.sentAt} /> },
		'ie460received': { header: 'Control decision at', body:row => <XMoment stacked date={row.ie460received} /> },
		'ie429received': { header: 'Received release', body:row => <XMoment stacked date={row.ie429received} /> },
		'ie451received': { header: 'No release decision at', body:row => <XMoment stacked date={row.ie451received} /> },
		'downloadButton': {
			className:'text-right',
			body: ({idmanifest, isSending}) => {
				return !isSending ? <DownloadExcelButton idmanifest={idmanifest}/> : <i className={'pi pi-spin pi-spinner'}/>
			}
		},
		'deleteButton': {
			className:'text-right',
			body: ({idmanifest, processed, isProcessing}) => {
				return !processed && !isProcessing
					? <DeleteManifestButton idmanifest={idmanifest} onSuccess={getManifests}/>
					: <i className={'pi pi-spin pi-spinner'} />
			}
		}
	}

	const config = keys.reduce((prev, curr) => [...prev,{field: [curr], ...columnHeaders[curr]}], []);

	return (
			<StandardPageableSearchableSpringBootDataTable
				tableProps={{
					header: headerTemplate
				}}
				column_config={config}
				fullRemoteContent={manifests}
				setPagesettings={setPagesettings}
				pagesettings={pagesettings}
				setSearchSettings={setSearchSettings}
				loading={loading}
				onRowSelect={e => {
					if (e.originalEvent.target.className.includes('button')){
						return;
					}
					appGoTo("/manifest/" + e.data.idmanifest);
				}}
			/>
	);
}

export default ManifestSummary;
