import { authenticatedGet, authenticatedPost } from './authenticated-api';
import { mapToURIParams } from './util';


export async function getAllManifests (token, pageable, searchable) {
	let url = `${process.env.REACT_APP_SERVER_URL}/nctsApp/declaration?${mapToURIParams(pageable)}&${mapToURIParams(searchable)}`
	return await authenticatedGet(token, url).then(({data}) => data.results);
}

export async function getManifestWithId (token, id) {
	let url = `${process.env.REACT_APP_SERVER_URL}/nctsApp/declaration/${id}`
	return await authenticatedGet(token, url).then(({data}) => data.results[0]);
}

export async function declareManifestForId (token, id) {
	let url = `${process.env.REACT_APP_SERVER_URL}/nctsApp/declare/${id}`
	return await authenticatedPost(token, url);
}
