import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import ApplicationContext from '../../../contexts/application-context/application-context';
import { StandardPageableSearchableSpringBootDataTable } from '../../../components/extra/DataTableWrappers/StandardPageableSearchableSpringBootDataTable';
import XMoment from '../../../components/extra/x-moment/x-moment';
import { DataTable } from 'primereact/datatable';
import { BEGATE_DECLARE, H7_DECLARE, NCTS_DECLARE } from '../../../contexts/application-context/application-keys';

const manifestTableConfig = [
	{ "field": "copid", "header": "id", filter: true, filterPlaceholder: 'Search by ID' },
	{ "field": "awb", "header": "AWB", filter: true, filterPlaceholder: 'Search by AWB' },
	{ "field": "eta", "header": "Arrived on", "body": row => <XMoment format="DD/MM/YYYY HH:mm:ss" date={row.eta}/>  },
	{ "field": "lastupdate", "header": "Last update", "body": row => <XMoment format="DD/MM/YYYY HH:mm:ss" date={row.lastupdate}/>  },
];

const transportTableConfig = [
	{ "field": "copid", "header": "id", filter: true, filterPlaceholder: 'Search by ID' },
	{ "field": "eta", "header": "Arrived on", "body": row => <XMoment format="DD/MM/YYYY HH:mm:ss" date={row.eta}/>  },
	{ "field": "lastupdate", "header": "Last update", "body": row => <XMoment format="DD/MM/YYYY HH:mm:ss" date={row.lastupdate}/>  },
];

const nctsTableConfig = [
	{ "field": "mrn", "header": "MRN", filter: true, filterPlaceholder: 'Search by MRN' },
	{ "field": "timestampSent", "header": "Declared at", "body": row => <XMoment format="DD/MM/YYYY HH:mm:ss" date={row.timestampSent}/>  },
	// { "field": "sentBy", "header": "Declared by"  },
	{ "field": "timestampReleased", "header": "Goods Released at", "body": row => !! row.timestampReleased && <XMoment format="DD/MM/YYYY HH:mm:ss" date={row.timestampReleased}/>  },
	{ "field": "errorDescription", "header": "Error message" },
	{ "field": "status", "header": "Status"}
];

export const ManifestList = ({title, reloadable, disableRowSelection, ...props}) => {
	const { getAccessTokenSilently } = useAuth0();
	const {currentApplication, getApi, defaultPageSettings, defaultSortSettings, appGoTo} = useContext(ApplicationContext);

	const tableConfig = {
		[H7_DECLARE]: manifestTableConfig,
		[BEGATE_DECLARE]: transportTableConfig,
		[NCTS_DECLARE]: nctsTableConfig
	}


	const [manifests, setManifests] = useState();
	const [loading, setLoading] = useState(false);
	const [pagesettings, setPagesettings] = useState({
		...defaultPageSettings,
		sort: defaultSortSettings,
	});
	const [searchSettings, setSearchSettings] = useState({});


	const getManifests = useCallback(async () => {
		try {
			setLoading(true);
			const token = await getAccessTokenSilently();
			setManifests(await getApi().getAllManifests(token, pagesettings, searchSettings));
			setLoading(false)
		} catch (error) {
			console.error(error)
		}

	}, [getAccessTokenSilently, setManifests, getApi, pagesettings, setLoading, searchSettings])

	useEffect(getManifests, [getManifests, currentApplication])


	return (
		<React.Fragment>
			{!!manifests ? (
				<StandardPageableSearchableSpringBootDataTable
					column_config={tableConfig[currentApplication.applicationType]}
					fullRemoteContent={manifests}
					setPagesettings={setPagesettings}
					pagesettings={pagesettings}
					setSearchSettings={setSearchSettings}
					loading={loading}
					// onRowSelect= {e => history.push("/manifest/" + e.data.idmanifest) }
					onRowSelect= {!disableRowSelection && (e => {
						let detail = currentApplication.ofType(NCTS_DECLARE) ? e.data.mrn : e.data.idmanifest
						appGoTo(`/manifest/${detail}`)
					})}
					reloadFn={!!reloadable && getManifests}
					title={title}
				/>
			): <DataTable emptyMessage={'No data available'}/>}
		</React.Fragment>
	);
}

