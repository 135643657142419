import React from 'react';
import { Splitter, SplitterPanel } from 'primereact/splitter';
import XTree from './tree/XTree';

const Portal = () => {
	return <Splitter className={'h-full'}>
		<SplitterPanel size={20}>
			<XTree/>

		</SplitterPanel>
		<SplitterPanel size={80}>
			<Splitter layout={'vertical'}>
				<SplitterPanel size={60}>

				</SplitterPanel>
				<SplitterPanel size={40}>

				</SplitterPanel>
			</Splitter>
		</SplitterPanel>
	</Splitter>
}

export default Portal;
