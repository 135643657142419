import React, { useContext } from 'react';
import { Card } from 'primereact/card';
import XButton from '../components/extra/buttons/x-button/XButton';
import { NO_APPLICATION_KEY } from '../contexts/application-context/application-keys';
import ApplicationContext from '../contexts/application-context/application-context';

const ApplicationSelectorPane = () => {
	const {setApplicationContext, allApplications} = useContext(ApplicationContext);


	if (Object.keys(allApplications).length === 1) {
		if (!Object.values(allApplications)[0].ofType(NO_APPLICATION_KEY)){
			setApplicationContext(Object.values(allApplications)[0].uuid)
		}
	}

	return <div className={'flex flex-wrap gap-3'}>
		{
			Object.values(allApplications).filter(e => !e.ofType(NO_APPLICATION_KEY) ).map(e => <div key={e.uuid} >
				{/*{JSON.stringify(e, null, 2)}*/}
				<Card subTitle={e.publicName} >
					<div className={'flex sm:flex-row md:flex-column gap-4 justify-content-around align-items-center'}>
						<div className={'flex flex-column mr-5'}>
							<span>uuid: {e.uuid}</span>
							<span>type: {e.applicationType}</span>
						</div>
						<XButton className={'sm:p-button-rounded md:p-button '} icon={'pi-chevron-right'} iconPos={'right'} onClick={() => {
							setApplicationContext(e.uuid)
						}} label={'Go to application'}/>
					</div>
				</Card>
			</div>)
		}
	</div>
}

export default ApplicationSelectorPane
