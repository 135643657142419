import React from 'react';
import './warning-banner.css';

const WarningBanner = ({warning}) => {

	return <div className={'warning-banner p-2 my-2 flex align-items-center'}>
		{/*<h4 className={'p-my-1 p-p-1 p-text-light p-d-flex p-align-center'}>*/}
			<i className={'pi pi-exclamation-circle mr-1'}/>
		{!!warning ? warning : 'These packages received an error on validation'}
		{/*</h4>*/}
	</div>
}

export default WarningBanner;
