import React, { useContext, useEffect } from 'react';
import ApplicationContext from './contexts/application-context/application-context';
import {
	BEGATE_DECLARE,
	H7_DECLARE,
	NCTS_DECLARE,
	NO_APPLICATION_KEY, XC_PORTAL
} from './contexts/application-context/application-keys';
import { NoApplicationApplication } from './contexts/application-context/no-application';
import XProgressSpinner from './components/extra/progress-spinner/XProgressSpinner';
import ManifestApplicationRouter from './applications/manageh7-begate/manifest-application-router';
import NctsApplicationRouter from './applications/ncts/ncts-application-router';
import { Switch} from 'react-router-dom';
import ProtectedRoute from './auth/protected-route';
import ApplicationSelectorPane from './applications/application-overview';
import XcPortalApplicationRouter from './applications/xc-portal/xc-portal-application-router';

export const AppRouter = () => {
	const { loadingApplications,} = useContext(ApplicationContext);

	if (loadingApplications) {
		return <div>
			<XProgressSpinner />
		</div>
	}


	return <Switch>
		<ProtectedRoute path={`/:applicationUuid`} component={SelectedApplication}/>
		<ProtectedRoute exact path={`/`} component={ApplicationSelectorPane}/>
	</Switch>
}

const SelectedApplication = (props) => {
	const {setApplicationContext, currentApplication} = useContext(ApplicationContext);
	const { applicationUuid } = props.match.params;

	useEffect(() => {
		!!applicationUuid && setApplicationContext(applicationUuid, true)
	}, [applicationUuid, setApplicationContext])

	switch (!!currentApplication && currentApplication.applicationType) {
		case NO_APPLICATION_KEY: return <NoApplicationApplication/>
		case H7_DECLARE: return <ManifestApplicationRouter/>
		case BEGATE_DECLARE: return <ManifestApplicationRouter/>
		case NCTS_DECLARE: return <NctsApplicationRouter/>
		case XC_PORTAL: return <XcPortalApplicationRouter/>
		default: return <NoApplicationApplication/>
	}
}


export default AppRouter;
