import React from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import ProtectedRoute from '../../auth/protected-route';
import { ManifestList } from '../common-files/manifest-list/ManifestList';
import NctsApplication from './ncts-app';

const NctsApplicationRouter = () => {
	let {path} = useRouteMatch();
	return <Switch>
		<ProtectedRoute exact path={`${path}`} component={NctsApplication}/>
		<ProtectedRoute path={`${path}/manifest`} component={ManifestList}/>
	</Switch>
}

export default NctsApplicationRouter;
