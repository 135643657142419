import React from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
import './XProgressSpinner.css'

const XProgressSpinner = ({...props}) => {

	return <div className={'flex align-items-center justify-content-center'}>
		<ProgressSpinner style={{ width: '5em', height: '5em' }} {...props} />
	</div>
}

export default XProgressSpinner;
