import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from 'primereact/button';

const LogoutButton = () => {
	const { logout } = useAuth0();
	return (
		<Button
			className="p-button-danger"
			onClick={() =>
				logout({
					returnTo: window.location.origin,
				})
			}
		>
			Log Out
		</Button>
	);
};

export default LogoutButton;
