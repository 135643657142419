import React from 'react';
import { Button } from 'primereact/button';
import './x-button.css'

const XButton = ({rounded, text, icon, ...props}) => {

	return <Button
		className={`x-button ${rounded ? 'p-button-rounded' : ''} ${text ? 'p-button-text' : ''}`}
		icon={!!icon && `pi ${icon}`}
		{...props}
	/>
}

export default XButton
