import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import LoadingButton from '../extra/buttons/loading-button';

const LoginButton = ({className, ...props}) => {
	const { loginWithRedirect } = useAuth0();
	return (
		<LoadingButton className={`flex justify-content-center ${className}`} execute={loginWithRedirect} {...props}>
			Log In
		</LoadingButton>
	);
};

export default LoginButton;
