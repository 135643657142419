import React, { useCallback, useEffect, useState } from 'react';
import XButton from './x-button/XButton';

const LoadingButton = ({execute, ...props}) => {
	const [isStillMounted, setMounted] = useState();
	const [loading, setLoading] = useState(false);

	const executeFn = useCallback(async () => {
		setLoading(true);
		await execute().then(e => {
			if (isStillMounted){
				setLoading(false)
			}
		});
	}, [setLoading, isStillMounted, execute])

	useEffect(() => {
		setMounted(true)
		return () => setMounted(false)
	}, [setMounted])

	return (
		<XButton loading={loading} onClick={executeFn} {...props} />
	)
}

export default LoadingButton;
