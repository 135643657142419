import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Paginator } from 'primereact/paginator';
import RefreshButton from '../buttons/refresh-button';


export const PageableTable = (props) => {

	let {column_config, value, valueFormat, title, reloadFn,  tableProps} = props;
	let {onPageChange, ...restTableProps} = tableProps;
	let {pageable, totalElements} = valueFormat

	return <React.Fragment>
		{(!!title || !!reloadFn) && <div className={'flex justify-content-between'}>
			{!!title && <h3>{title}</h3>}
			<RefreshButton onClick={reloadFn} />
		</div>}
		<DataTable value={value} autoLayout={true} filterDisplay={'row'} className={'p-datatable-sm'} emptyMessage={"No data available"} {...restTableProps}>
			{
				column_config.map(e => <Column key={e.field}  {...e} showFilterMenu={false} /> )
			}
		</DataTable>

		<Paginator
			totalRecords={totalElements}
			rows={pageable && pageable.pageSize}
			first={pageable && pageable.offset}
			template={'CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown'}
			onPageChange={onPageChange}
			currentPageReportTemplate={'{first} - {last} of {totalRecords} items ({totalPages} pages)'}
			currentPage={pageable && pageable.pageNumber}
		/>
	</React.Fragment>
}
