import React from 'react';
import Moment from 'react-moment';

export const XMoment = ({stacked, ...props}) => !!stacked
	? <div className={'flex flex-column'}>
		<XMoment parse={'YYYYMMDDHHmmssZZ'} format="DD/MM/YYYY" {...props} />
		<XMoment parse={'YYYYMMDDHHmmssZZ'} format="HH:mm:ss" {...props} />
	</div>
	: <Moment parse={'YYYYMMDDHHmmssZZ'} format={'DD/MM/YYYY HH:mm:ss'} {...props}/>

export default XMoment;
