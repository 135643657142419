import React, { useContext } from "react";

import AuthNav from "./auth-nav";
import { Menubar } from 'primereact/menubar';
import ApplicationSelector from './application-selector';
import { useAuth0 } from '@auth0/auth0-react';
import ApplicationContext from '../../contexts/application-context/application-context';
import { BEGATE_DECLARE, H7_DECLARE, NCTS_DECLARE } from '../../contexts/application-context/application-keys';
const NavBar = () => {
	let {isAuthenticated} = useAuth0();
	let {currentApplication, appGoTo} = useContext(ApplicationContext);
	// let {applicationType} = currentApplication;

	const items = isAuthenticated && !!currentApplication && [H7_DECLARE, BEGATE_DECLARE, NCTS_DECLARE].includes(currentApplication.applicationType) ?  [
		{
			label: 'Home',
			command: () => appGoTo("/")
		},
		{
			label: 'Manifest',
			command: () => appGoTo ("/manifest")
		}
	] : []

	return (
		<Menubar className={'mb-2'} model={items} end={<NavbarEnd/>}/>
	);
};

const NavbarEnd = () => {
	return <React.Fragment>
			<div className={'flex align-items-center'}>
				<ApplicationSelector />
				<AuthNav/>
			</div>
		</React.Fragment>
}


export default NavBar;
