import React, { createContext, useCallback, useEffect, useState } from 'react';
import * as h7Api from '../../api/manifest';
import * as begateApi from '../../api/begate';
import * as nctsApi from '../../api/ncts';
import { useAuth0 } from '@auth0/auth0-react';
import { getAllAvailableApplications } from '../../api/xcomm-api';
import { noApplication } from './no-application';
import { BEGATE_DECLARE, H7_DECLARE, NCTS_DECLARE, NO_APPLICATION_KEY } from './application-keys';
import { useHistory } from 'react-router-dom';

const ApplicationContext = createContext();

export default ApplicationContext;

const defaultSort = 'arrivedon,desc';
const defaultPage = {
	page:0,
	size: 10,
};

export function ApplicationContextProvider ({children}) {
	let REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL
	let REACT_APP_MGMT_ENDPOINT = `${REACT_APP_SERVER_URL}${process.env.REACT_APP_MGMT_ENDPOINT}`;

	let [applications, setApplications] = useState({ [noApplication.uuid]: noApplication })
	let [currentApplication, setCurrentApplication] = useState(applications[Object.keys(applications)[0]])
	let [loading, setLoading] = useState(false);

	let history = useHistory();

	let clearCurrentApplication =() => {
		history.push(`/`)
		setCurrentApplication(noApplication)
	}

	let appGoTo = (page) => {
		let _page = page.startsWith('/') ? page.substring(1) : page
		history.push(`/${currentApplication.uuid}/${_page}`)
	}

	//provide an application uuid
	let setApplicationContext = (uuid, dontMove ) => {
		!dontMove && history.push(`/${uuid}`)
		setCurrentApplication(applications[uuid])
	}

	const { getAccessTokenSilently } = useAuth0();
	const getApplications = useCallback( async () => {
		setLoading(true)
		try {
			const token = await getAccessTokenSilently();
			let appConfig = await getAllAvailableApplications(REACT_APP_MGMT_ENDPOINT)(token);
			// Als ik de applicatie's remote heb opgehaald ga ik het object hermappen om overeen te komen met hoe de objecten nu hard geprogrammeerd zijn.
			let mappedAppObject = !!appConfig && appConfig.reduce((prev, {serverUrl, appEndpoint, applicationType, ...curr}) => {
				let config = curr?.config && JSON.parse(curr.config);
				return {...prev,
					[curr.uuid]: {
						label: curr.publicName,
						value: curr.uuid,
						uuid: curr.uuid,
						applicationType,
						serverUrl,
						appEndpoint,
						ofType: type => applicationType === type,
						...curr,
						config,
					}
				}
			}, {noApplication})

			//TODO: Effe checken als er wel shit is toegekomen, anders no application laten staan
			setApplications(mappedAppObject)
			if (Object.values(mappedAppObject).length === 2) {
				setApplicationContext(Object.values(mappedAppObject).filter(e => e.applicationType !== NO_APPLICATION_KEY)[0].uuid, true)
			}
		} catch (e) {
			console.error(e)
			// toast({ severity: 'error', summary: 'Could not get fetch', detail: e.message })
		}
		setLoading(false)
	}, [getAccessTokenSilently, setApplications, REACT_APP_MGMT_ENDPOINT, setLoading]);

	useEffect(getApplications, [getApplications])

	let sort = currentApplication?.config?.sort ?? defaultSort
	let page = currentApplication?.config?.page ?? defaultPage;

	const context = {
		currentApplication,
		loadingApplications: loading,
		allApplications: applications,
		defaultPageSettings: page,
		defaultSortSettings: sort,
		setApplicationContext,
		clearCurrentApplication,
		appGoTo,
		getApi: () => {
			if ((currentApplication).ofType(H7_DECLARE)) {
				return { ...h7Api };
			} else if ((currentApplication).ofType(BEGATE_DECLARE)) {
				return { ...begateApi };
			} else if ((currentApplication).ofType(NCTS_DECLARE)) {
				return { ...nctsApi };
			}
		},
	}


	return <ApplicationContext.Provider value={context} >
		{children}
	</ApplicationContext.Provider>
}
